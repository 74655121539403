.content-style {
  padding: 12px 16em;
  margin-top: auto;
  /* display: grid;
  place-items: center; */
}

.header-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-layout-header {
  background: #fff !important;
  padding: 0 24px !important;
}

.ant-drawer-body {
  padding: 0px !important;
}

.mt-1 {
  margin-top: 1em;
}

.center-text {
  text-align: center;
}

.ant-card-body {
  padding: 12px !important;
}

.footer-style {
  text-align: center;
  padding: 12px;
}

@media only screen and (max-width: 1100px) {
  .content-style {
    padding: 12px 5em;
  }
}

@media only screen and (max-width: 768px) {
  .content-style {
    padding: 12px;
  }
}
